<template>
  <el-row v-if="!permissionDenied">
    <el-row v-if="video !== null" class="movie-list">
      <el-col :md="15">
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-row>
                <h3 v-html="video.title" />
              </el-row>
              <el-row style="color: #999;font-size: 16px;padding-top: 0px;">
                <span><i class="el-icon-video-play">{{ video.view }}</i></span>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <span><i class="el-icon-s-comment">{{ video.comment }}</i></span>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <span><i class="el-icon-watch">{{ video.pubDate }}</i></span>
                <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp;'" />
                <span v-if="videoId !== null && videoId.includes('BV')"><i class="el-icon-apple">
                  <a target="_blank" :href="`https://bilibili.com/` + videoId">bili</a>
                </i></span>
              </el-row>
            </div>
            <div class="text item">
              <video-player :video-prop="video" />
            </div>
          </el-card>
        </el-row>
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <div class="video-data-row">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-plus"
                  :disabled="isCollected"
                  @click="collection(video.videoId)"
                >
                  <span>收藏 {{ video.favorite }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-thumb"
                  :disabled="isCollected"
                  @click="collection(video.videoId)"
                >
                  <span>喜欢 {{ video.thumbUp }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-share"
                  :disabled="isCollected"
                  @click="getShareUrl(video.videoId)"
                >
                  <span>分享 {{ video.share }}</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-download"
                  @click="getDownloadUrl(video.videoId)"
                >
                  <span>下载</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="deleteVideo(video)"
                >
                  <span>删除</span>
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-help"
                  @click="displayErrorReportDialog"
                >
                  <span>报错</span>
                </el-button>
              </div>
            </div>
            <div class="text item">
              <!--视频描述行-->
              <span class="description" v-html="video.description" />
              <el-divider />
              <!--视频标签行-->
              <div class="v-tag">
                <el-tag
                  v-for="(tag,index) in video.tags"
                  :key="index"
                  class="tag"
                  size="medium"
                  effect="plain"
                >
                  <router-link target="_blank" :to="`/video/tag/` + tag">
                    {{ tag }}
                  </router-link>
                </el-tag>
              </div>
            </div>
          </el-card>
        </el-row>
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-row>
                <h3>视频评论</h3>
              </el-row>
            </div>
            <div class="text item">
              <div ref="comment" :style="wrapStyle" class="comment-wrap">
                <comment
                  v-model="dataList"
                  :user="currentUser"
                  :props="props"
                  :before-submit="submit"
                  :before-like="like"
                  :before-delete="deleteComment"
                  :upload-img="uploadImg"
                />
                <el-pagination
                  :small="screenWidth <= 768"
                  hide-on-single-page
                  layout="prev, pager, next"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  :total="totalSize"
                  @current-change="handleCurrentChange"
                />
              </div>
            </div>
          </el-card>
        </el-row>
      </el-col>
      <el-col :md="9">
        <el-row>
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <user-avatar-card v-if="user !== null" :user-avatar="user" />
          </el-row>
        </el-row>
        <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-row>
                <h3>接下来播放</h3>
              </el-row>
              <el-row>
                <span>自动播放 <el-switch v-model="autoPlay" /></span>
              </el-row>
            </div>
          </el-card>
        </el-row>
        <el-row v-for="(item,index) in similarVideos" :key="index" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <side-video-card :video="item" />
        </el-row>
      </el-col>

      <!-- 添加到播放列表对话框 -->
      <el-dialog
        append-to-body
        :visible.sync="showPlaylistDialog"
        width="30%"
        center
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="createAlbum">
              创建播放列表
            </el-button>
            <el-form v-if="showCreateAlbum" ref="form" :model="albumForm" label-width="80px">
              <el-form-item>
                <el-input v-model="albumForm.albumName" style="padding-right: 1px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
              <el-form-item>
                <el-button type="plain" @click="onCreateAlbum">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="text item">
            <el-table
              :data="playlist"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="albumName"
                label="播放列表"
              />
              <el-table-column
                prop="total"
                label="数量"
              />
              <el-table-column label="收藏" width="80" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-plus"
                    @click="addToPlaylist(scope.row)"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-dialog>

      <!-- 视频报错对话框 -->
      <el-dialog
        append-to-body
        :visible.sync="showErrorReportDialog"
        width="30%"
        center
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>视频报错</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="submitErrorReport">提交错误</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="errorReportForm" label-width="80px">
              <el-form-item label="错误类型">
                <el-select v-model="errorReportForm.errorCode" placeholder="选择视频错误类型">
                  <el-option label="视频无封面" value="1" />
                  <el-option label="视频无声音" value="2" />
                  <el-option label="视频无画面" value="3" />
                  <el-option label="视频无资源" value="4" />
                  <el-option label="视频有广告" value="5" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-dialog>
    </el-row>
  </el-row>
  <el-row v-else>
    <permission-denied-card :text-object="textObject" />
  </el-row>
</template>

<script>
import PermissionDeniedCard from '@/components/card/PermissionDeniedCard'
import VideoPlayer from 'components/VideoPlayer'
import SideVideoCard from 'components/card/SideVideoCard'
import UserAvatarCard from '@/components/card/UserAvatarCard'
import comment from '@/components/comment'

import {similarVideo, videoInfo, videoErrorReport, downloadVideo, getShortUrl, videoErrorDelete} from '@/api/video'
import { collectItem, createAlbum, getUserPlaylist } from '@/api/collect'
import { getUserInfo } from '@/api/user'
import { publishComment, getComment } from '@/api/comment'
import { getAuthedUser } from '@/utils/auth'
import {deletePaper} from "@/api/exam";

export default {
  name: 'VideoPage',
  components: { SideVideoCard, VideoPlayer, UserAvatarCard, PermissionDeniedCard, comment },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 20,
      totalSize: 0,
      dataList: [],
      // ********************************************************************/
      wrapStyle: '',
      videoComments: [
        {
          commentId: 114511,
          content: 'this is comment content',
          imageUrl: '',
          children: [],
          likes: 0,
          liked: false,
          reply: null,
          createAt: 1700271326393,
          user: {
            userId: 1,
            name: '西瓜',
            avatar: ''
          }
        }
      ],
      currentUser: {
        userId: 9999,
        name: '芒果',
        avatar: '//picx.zhimg.com/v2-a2c89378a6332cbfed3e28b5ab84feb7.jpg',
        author: true
      },
      // 自定义组件中 comment 对象的字段名
      props: {
        id: 'commentId',
        content: 'content',
        imgSrc: 'imageUrl',
        children: 'children',
        likes: 'likes',
        liked: 'liked',
        reply: 'reply',
        createAt: 'createAt',
        total: 'total',
        user: 'user'
      },
      // ********************************************************************/
      videoId: null,
      video: null,
      user: null,
      similarVideos: [],
      isCollected: false,
      showPlaylistDialog: false,
      playlist: [],
      accessCodeForm: {
        contentId: null,
        contentType: 1002,
        accessCode: null
      },
      showErrorReportDialog: false,
      errorReportForm: {
        videoId: null,
        errorCode: null
      },
      permissionDenied: false,
      textObject: {
        content: '视频',
        route: '/video'
      },
      autoPlay: false,
      showCreateAlbum: false,
      albumForm: {
        albumName: null
      }
    }
  },
  watch: {
    // 地址栏 url 发生变化时重新加载本页面
    $route() {
      this.$router.go()
    }
  },
  created() {
    const loginUser = getAuthedUser()
    if (loginUser != null) {
      this.currentUser = {
        userId: loginUser.userId,
        name: loginUser.screenName,
        avatar: loginUser.avatarUrl,
        author: true
      }
    }

    this.videoId = this.$route.params.id
    this.accessCodeForm.contentId = this.videoId
    this.getVideoInfo(this.videoId)
    this.getSimilarVideos(this.videoId)
    this.getCommentWrapper(this.currentPage)
  },
  mounted() {
    const header = this.$refs.header
    if (header !== undefined && header !== null) {
      this.wrapStyle = `height: calc(100vh - ${header.clientHeight + 20}px)`
    }
  },
  methods: {
    // ****************************************************************************************************************
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      this.getCommentWrapper(currentPage)
      // 回到顶部
      scrollTo(0, 0)
    },
    getCommentWrapper(pageNumber) {
      getComment(this.videoId, pageNumber).then(resp => {
        if (resp.code === 0) {
          const respData = resp.data
          this.dataList = respData.list
          this.totalSize = respData.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    // ****************************************************************************************************************
    // 获取视频的详细信息
    getVideoInfo(videoId) {
      videoInfo(videoId).then(resp => {
        if (resp.code === 0) {
          this.video = resp.data
          document.title = resp.data.title
          this.userId = resp.data.userId
          getUserInfo(this.userId).then(resp => {
            if (resp.code === 0) {
              this.user = resp.data
            } else {
              this.$notify.error({
                message: '用户数据获取失败',
                type: 'warning',
                duration: 3000
              })
            }
          })
        } else if (resp.code === 2) {
          this.$router.push('/404')
        } else {
          this.permissionDenied = true
        }
      }).catch(error => {
        this.$notify.error({
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    // 获取和当前视频类似的其他视频
    getSimilarVideos(videoId) {
      similarVideo(videoId).then(resp => {
        if (resp.code === 0) {
          this.similarVideos = resp.data
        } else {
          this.$notify.error({
            message: '推荐视频数据获取失败',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify.error({
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    // 换一换
    refreshSimilar() {
      console.log('刷新相关推荐')
    },
    // 用户点击收藏
    collection(videoId) {
      this.showPlaylistDialog = true
      const queryInfo = {
        pn: 1,
        userId: this.currentUser.userId
      }
      getUserPlaylist(queryInfo).then(resp => {
        if (resp.code === 0) {
          this.playlist = resp.data.list
        }
      })
    },
    addToPlaylist(row) {
      this.showPlaylistDialog = false
      const jsonData = {}
      jsonData.albumId = row.albumId
      jsonData.postId = this.videoId
      jsonData.action = 1
      collectItem(jsonData).then(resp => {
        if (resp.code === 0) {
          this.$notify.success({
            title: '视频已收藏',
            type: 'success',
            duration: 3000
          })
        } else {
          this.$notify.warning({
            title: '视频收藏失败',
            type: 'warning',
            duration: 3000
          })
        }
      })
    },
    getShareUrl(videoId) {
      getShortUrl(videoId).then(resp => {
        if (resp.code === 0) {
          console.log(resp.data)
          this.video.share += 1
        }
      })
    },
    getDownloadUrl(videoId) {
      // let filename
      downloadVideo(videoId).then(resp => {
        if (resp.code === 0) {
          const downloadUrl = resp.data.url
          window.open(downloadUrl, '_blank')
          /* fetch(downloadUrl.url, {
            headers: {
              Authorization: 'Bearer ' + downloadUrl.token
            },
            method: 'GET',
            credentials: 'include'
          }).then(resp => {
            /!*
            遍历 formdata
            for (const key of resp.headers.keys()) {
              console.log(key + ' : ' + resp.headers.get(key))
            }*!/
            const header = resp.headers.get('Content-Disposition')
            const parts = header.split(';')
            const encodeFilename = parts[1].split('=')[1]
            filename = decodeURI(encodeFilename)
            return resp.blob()
          }).then(data => {
            const blobUrl = window.URL.createObjectURL(data)
            const a = document.createElement('a')
            a.download = filename
            a.href = blobUrl
            a.click()
          }).catch(e => {
            this.$notify({
              title: '提示',
              message: '视频下载失败',
              type: 'warning',
              duration: 3000
            })
          })*/
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    createAlbum() {
      this.showCreateAlbum = true
    },
    onCreateAlbum() {
      this.showCreateAlbum = false
      createAlbum(this.albumForm).then(resp => {
        if (resp.code === 0) {
          this.playlist.push(resp.data)
        }
      })
      this.albumForm.albumName = null
    },
    deleteVideo(video) {
      this.$confirm('确定要删除 ' + video.title + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'msgbox'
      }).then(() => {
        const videoId = video.videoId
        const errorReportForm = {
          videoId: videoId,
          errorCode: 4
        }

        videoErrorDelete(errorReportForm).then(resp => {
          if (resp.code === 0) {
            this.errorReportForm.errorCode = null
            this.$notify({
              title: '提示',
              message: '视频错误已提交',
              type: 'warning',
              duration: 3000
            })
          } else {
            this.$notify({
              title: '提示',
              message: resp.msg,
              type: 'warning',
              duration: 3000
            })
          }
        }).catch(error => {
          this.$notify({
            title: '提示',
            message: error.message,
            type: 'warning',
            duration: 3000
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    displayErrorReportDialog() {
      this.errorReportForm.videoId = this.video.videoId
      this.showErrorReportDialog = true
    },
    submitErrorReport() {
      this.showErrorReportDialog = false
      videoErrorReport(this.errorReportForm).then(resp => {
        if (resp.code === 0) {
          this.errorReportForm.errorCode = null
          this.$notify({
            title: '提示',
            message: '视频错误已提交',
            type: 'warning',
            duration: 3000
          })
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    },
    // ****************************************************************************************************************
    // 评论
    async submit(newComment, parent, add) {
      const res = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({ newComment, parent })
        }, 300)
      })

      add(Object.assign(res.newComment, { postId: this.video.videoId }))
      if (res.parent !== null) {
        // console.log('parent: ', res.parent)
      } else {
        this.totalSize += 1
      }

      // console.log('addComment: ', res)
      publishComment(res).then(resp => {
        if (resp.code === 0) {
          this.$notify.success({
            message: '评论已发布',
            duration: 3000
          })
        } else {
          this.$notify.warning({
            message: '评论发布失败',
            duration: 3000
          })
        }
      })
    },
    async like(comment) {
      const res = await new Promise((resolve) => {
        setTimeout(() => {
          resolve(comment)
        }, 0)
      })

      console.log('likeComment: ', res)
    },
    async uploadImg({ file, callback }) {
      const res = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result)
        }

        reader.onerror = () => {
          reject(reader.error)
        }
      })
      callback(res)
      console.log('uploadImg： ', res)
    },
    async deleteComment(comment, parent) {
      const res = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({ comment, parent })
        }, 300)
      })
      console.log('deleteComment: ', res)
    }
    // ****************************************************************************************************************
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .msgbox{
    width: 320px !important;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 5%;
  padding-right: 5%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.v-tag {
  padding-top: 10px;
}
.tag{
  margin-right: 3px;
}
</style>
